<template>
  <div class="startseite">
    <div class="heading">
      <div class="overlay-red">
        <nav>
          <router-link class="link-login" :to="{ name: 'Login' }">{{lang.startseite.navLoginText}}</router-link>
          <md-button :to="{ name: 'CreateAccount' }" class="md-raised md-filled md-secondary">{{ lang.startseite.navStartText }}</md-button>
        </nav>
        <header>
          <div class="introduction">
            <div class="headlines" data-aos="fade-in-left" data-aos-once="true">
              <h1>{{ lang.startseite.header1 }}</h1>
              <h2>{{ lang.startseite.header2 }}</h2>
              <md-button :to="{ name: 'CreateAccount' }" class="md-raised">{{lang.startseite.headerButtonText}}</md-button>
            </div>
            <div class="image-container">
              <div class="phone" data-aos="fade-in-right" data-aos-delay="300" data-aos-once="true">
                <div class="screen">
                  <VueSlickCarousel
                    class="slick-desktop"
                    :pauseOnHover="false"
                    :draggable="false"
                    :autoplay="true"
                    :autoplaySpeed="3500"
                    :infinite="true"
                    :arrows="false"
                    :dots="false"
                  >
                    <div class="slick-container">
                      <img :src="'/img/beerpong-images/startseite_mock_' + lang.name + '.png'" :alt="lang.startseite.imgAlt1"/>
                    </div>
                    <div class="slick-container">
                      <img :src="'/img/beerpong-images/startseite_mock2_' + lang.name + '.png'" :alt="lang.startseite.imgAlt2"/>
                    </div>
                    <div class="slick-container">
                      <img :src="'/img/beerpong-images/startseite_mock3_' + lang.name + '.png'" :alt="lang.startseite.imgAlt3"/>
                    </div>
                    <div class="slick-container">
                      <img :src="'/img/beerpong-images/startseite_mock4_' + lang.name + '.png'" :alt="lang.startseite.imgAlt4"/>
                    </div>
                    <div class="slick-container">
                      <img :src="'/img/beerpong-images/startseite_mock5_' + lang.name + '.png'" :alt="lang.startseite.imgAlt5"/>
                    </div>
                    <div class="slick-container">
                      <img :src="'/img/beerpong-images/startseite_mock6_' + lang.name + '.png'" :alt="lang.startseite.imgAlt6"/>
                    </div>
                  </VueSlickCarousel>
                </div>
                <div class="home"></div>
                <div class="speaker"></div>
              </div>
              <div class="container-laptop" data-aos="fade-in-right" data-aos-delay="300" data-aos-once="true">
                <div class="laptop">
                  <div class="screen" :class="lang.name == 'de' ? 'screen-de' : 'screen-en'"></div>
                  <div class="base"></div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
    <main>
      <article class="features">
        <h3>{{lang.startseite.article1Heading}}</h3>
        <div class="card-container">
          <div class="card" data-aos="fade-up" data-aos-once="true">
            <i class="far fa-clock"></i>
            <h4>{{lang.startseite.slickHeader1}}</h4>
            <p>{{lang.startseite.slickParagraph1}}</p>
          </div>
          <div class="card" data-aos="fade-up" data-aos-once="true">
            <i class="fas fa-beer"></i>
            <h4>{{lang.startseite.slickHeader2}}</h4>
            <p>{{lang.startseite.slickParagraph2}}</p>
          </div>
          <div class="card" data-aos="fade-up" data-aos-once="true">
            <i class="fas fa-mobile"></i>
            <h4>{{lang.startseite.slickHeader3}}</h4>
            <p>{{lang.startseite.slickParagraph3}}</p>
          </div>
          <div class="card" data-aos="fade-up" data-aos-once="true">
            <i class="fas fa-bezier-curve"></i>
            <h4>{{lang.startseite.slickHeader4}}</h4>
            <p>{{lang.startseite.slickParagraph4}}</p>
          </div>
        </div>
      </article>
      <article class="layouts">
        <div class="preview-container">
            <div class="phone" data-aos="fade-in-left" data-aos-once="true">
                <div class="screen">
                    <VueSlickCarousel
                        class="slick-desktop slick-desktop-mobile"
                        :pauseOnHover="false"
                        :fade="true"
                        :draggable="false"
                        :autoplay="false"
                        :autoplaySpeed="8000"
                        :infinite="true"
                        :arrows="true"
                        :dots="true"
                    >
                    <div class="slick-container">
                        <div class="slick-content">
                            <img  :src="'/img/beerpong-images/startseite_mock_' + lang.name + '.png'" :alt="lang.startseite.imgAlt1"/>
                            <div class="description" data-aos="fade-in-right" data-aos-delay="300" data-aos-once="true">
                                <h3>{{lang.startseite.slickHeadline1}}</h3>
                                <ul class="ul-unstyled">
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList1Element1}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList1Element2}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList1Element3}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList1Element4}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="slick-container">
                        <div class="slick-content">
                            <img :src="'/img/beerpong-images/startseite_mock2_' + lang.name + '.png'" :alt="lang.startseite.imgAlt2"/>
                            <div class="description">
                                <h3>{{lang.startseite.slickHeadline2}}</h3>
                                <ul class="ul-unstyled">
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList2Element1}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList2Element2}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList2Element3}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList2Element4}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="slick-container">
                        <div class="slick-content">
                            <img :src="'/img/beerpong-images/startseite_mock3_' + lang.name + '.png'" :alt="lang.startseite.imgAlt3"/>
                            <div class="description">
                                <h3>{{lang.startseite.slickHeadline3}}</h3>
                                <ul class="ul-unstyled">
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList3Element1}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList3Element2}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList3Element3}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="slick-container">
                        <div class="slick-content">
                            <img :src="'/img/beerpong-images/startseite_mock4_' + lang.name + '.png'" :alt="lang.startseite.imgAlt4"/>
                            <div class="description">
                                <h3>{{lang.startseite.slickHeadline4}}</h3>
                                <ul class="ul-unstyled">
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList4Element1}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList4Element2}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList4Element3}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList4Element4}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="slick-container">
                        <div class="slick-content">
                            <img :src="'/img/beerpong-images/startseite_mock5_' + lang.name + '.png'" :alt="lang.startseite.imgAlt5"/>
                            <div class="description">
                                <h3>{{lang.startseite.slickHeadline5}}</h3>
                                <ul class="ul-unstyled">
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList5Element1}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList5Element2}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList5Element3}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="slick-container">
                        <div class="slick-content">
                            <img :src="'/img/beerpong-images/startseite_mock6_' + lang.name + '.png'" :alt="lang.startseite.imgAlt6"/>
                            <div class="description">
                                <h3>{{lang.startseite.slickHeadline6}}</h3>
                                <ul class="ul-unstyled">
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList6Element1}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList6Element2}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList6Element3}}</span>
                                    </li>
                                    <li>
                                        <i class="fas fa-check"></i>
                                        <span>{{lang.startseite.slickList6Element4}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </VueSlickCarousel>
                </div>
                <div class="home"></div>
                <div class="speaker"></div>
            </div>
        </div>
      </article>
      <article class="start-now">
          <div class="overlay-blue">
              <div class="teaser">
                <h3>
                  <span>{{lang.startseite.startNowHeaderPart1}} </span>
                  <span>{{lang.startseite.startNowHeaderPart2}} </span>
                  <span>{{lang.startseite.startNowHeaderPart3}}</span>
                </h3>
                <md-button :to="{ name: 'CreateAccount' }" class="md-raised">{{lang.startseite.headerButtonText}}</md-button>
              </div>
          </div>
      </article>
    </main>
    <footer>
        <LanguageChanger class="language-changer" :cssLight="false"/>
        <ul class="ul-unstyled">
            <li><router-link :to="{ name: 'Impressum' }">{{lang.startseite.footerLinkText1}}</router-link></li>
            <li><router-link :to="{ name: 'Datenschutz' }">{{lang.startseite.footerLinkText2}}</router-link></li>
            <li><router-link :to="{ name: 'Kontakt' }">{{lang.startseite.footerLinkText3}}</router-link></li>
        </ul>
    </footer>
  </div>
</template>

<script>
import store from "@/store/index";
import LanguageChanger from "@/components/Utils/LanguageChanger";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "StartseiteDesktop",
  components: {
    LanguageChanger,
    VueSlickCarousel,
    LanguageChanger,
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/base";
.slick-dots {
  li {
    button:before {
      color: $color-white;
      font-size: 12px;
      opacity: 1;
    }

    &.slick-active {
      button:before {
        color: $brand-primary !important;
      }
    }
  }
}

.slick-desktop-mobile {
  .slick-dots {
    bottom: -95px;
    left: -350px;
  }

  .slick-dots li button:before {
    color: $border-gadget;
  }

  .slick-content {
    display: flex;
  }

  .slick-next {
    transition: .3s ease all;
    right: 660px;

    &:hover {
      right: 657px;

      &:before {
        color: $brand-primary;
      }
    }
  }

  .slick-prev {
    transition: .3s ease all;
    left: -50px;

    &:hover {
      left: -53px;

      &:before {
        color: $brand-primary;
      }
    }
  }

  .slick-prev:before, .slick-next:before {
    color: $border-gadget;
    font-size: 25px;
    opacity: 1;
  }
}

@media (min-width: $screen-xs2) and (max-width: $screen-md) {
  .slick-desktop-mobile {
    .slick-dots {
      left: 0px;
      bottom: -85px;
    }

    .slick-next {
      right: -40px;
      top: 270px;

      &:hover {
        right: -43px;
      }
    }

    .slick-prev {
      top: 270px;
    }
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
  .slick-desktop-mobile {
    .slick-dots {
      bottom: -95px;
      left: -305px;
    }

    .slick-next {
      right: 566px;

      &:hover {
        right: 563px;
      }
    }
  }
}

@media (max-width: $screen-xs2) {
  .slick-desktop-mobile {
    .slick-dots {
      left: 0px;
      bottom: -85px;
    }
    .slick-next {
      display: none !important;
    }

    .slick-prev {
      display: none !important;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/scss/base";

[data-aos="fade-in-right"] {
  opacity: 0;
  transform: translateX(3rem);

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}

[data-aos="fade-in-left"] {
  opacity: 0;
  transform: translateX(-3rem);

  &.aos-animate {
    opacity: 1;
    transform: translateX(0);
  }
}


[data-aos="fade-in-up"] {
  opacity: 0;
  transform: translateY(3rem);

  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}

$padding-left-container: 45px;
$height-startseite: 590px;

.heading {
  background-image: url("/img/beerpong-images/1920px/beer-pong-team-desktop-5.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: $height-startseite;
  position: relative;
}

.overlay-red {
  background: rgba(251, 88, 59, 0.9);
  background: linear-gradient(60deg, rgba(255, 0, 0, 0.94) 0%, rgba(84, 206, 255, 0.9) 100%);
  height: 100%;
  width: 100%;
}

.overlay-blue {
  background: rgba(50, 100, 245, 0.90);
  background: linear-gradient(45deg, rgba(253, 129, 181, 0.9) 0%, rgba(84, 206, 255, 0.95) 50%, rgba(128, 137, 255, 0.9) 100%);
  height: 100%;
  width: 100%;
}

nav {
  align-items: center;
  display: flex;
  padding: 15px 25px 0 0;
  position: absolute;
  right: 0;
  z-index: 2;

  .link-login {
    color: $color-white !important;
    font-size: 1.8rem;
    font-weight: 400;
    margin-right: 25px;

    &:hover {
      color: $color-text-header !important;
      text-decoration: none;
    }
  }
}

header {
  align-items: initial;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  height: auto;
  position: absolute;
  top: auto;
  width: 100%;
  z-index: 1;
  height: 680px;
  overflow: hidden;
}

h1, h2, h3, h4, p, span {
  font-family: $font-ubuntu $font-default;
}

.introduction {
  display: flex;
  padding: 0 $padding-left-container;
  align-items: center;
  height: $height-startseite;

  h1 {
    color: $color-white;
    font-size: 4.8rem;
    font-weight: 600;
    line-height: 7.5rem;
    margin: 0 100px 30px 0;
    width: 420px;
    font-family: $font-ubuntu $font-default;
  }

  h2 {
    color: $color-text-header;
    font-family: $font-ubuntu $font-default;
    font-size: 2.3rem;
    font-weight: 400;
    margin-bottom: 40px;
    width: 600px;
  }

  .md-button {
    padding: 0 18px !important;
    margin: 0;
  }

  .image-container {
    margin-top: 20px;

    .phone {
        margin-left: 80px;
        position: absolute;
        top: 35px;
    }
  }
}

.phone {
  width: 31rem;
  background: $color-white;
  border-radius: 3rem;
  border: 2px solid $border-gadget;
  position: relative;
  height: 64rem;
  
  .screen {
        border: 1px solid $border-gadget;
        box-sizing: border-box;
        height: 52rem;
        left: 50%;
        margin: -26rem -14.5rem;
        position: absolute;
        top: 50%;
        width: 29rem;
    }

    .home {
        border-radius: 50%;
        border: 1px solid $border-gadget;
        bottom: 10px;
        height: 3.6rem;
        left: 50%;
        margin: 0 -18px;
        position: absolute;
        width: 3.6rem;
    }

    .speaker {
        border-radius: 0.6rem;
        border: 1px solid $border-gadget;
        height: 0.6rem;
        left: 50%;
        margin: 0 -25px;
        position: absolute;
        top: 25px;
        width: 5rem;
    }
}

.container-laptop {
  height: 570px;
  left: 840px;
  margin-top: 0;
  margin: auto;
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 100px;
  z-index: -1;

  .laptop {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 100;

    .screen-en {
      &::after {
        background-image: url("/img/beerpong-images/startseite_mock_desktop_en.png");
      }
    }

    .screen-de {
      &::after {
        background-image: url("/img/beerpong-images/startseite_mock_desktop_de.png");
      }
    }

    .screen {
      background: $color-laptop;
      border-radius: 12px 12px 0px 0px;
      border: 3px solid $border-gadget;
      box-sizing: border-box;
      height: 440px;
      left: 115px;
      position: absolute;
      top: 0px;
      width: 710px;

      &::after,
      &::before {
        content: "";
        position: absolute;
      }

      &::after {
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 3px solid $border-gadget;
        box-sizing: border-box;
        height: 382px;
        left: 18px;
        top: 30px;
        width: 668px;
      }

      &::before {
        background: #777;
        border-radius: 50%;
        height: 8px;
        left: calc(50% - 4px);
        top: 10px;
        width: 8px;
      }
    }
  }

  .base {
    background: $color-laptop;
    border-radius: 0px 0px 15px 15px;
    border: 3px solid $border-gadget;
    box-sizing: border-box;
    height: 28px;
    left: 75px;
    position: absolute;
    top: 437px;
    width: 790px;

    &::after {
      content: "";
      background: #ddd;
      background: #fff;
      border-radius: 0px 0px 8px 8px;
      border-top: none;
      border: 2px solid $border-gadget;
      box-sizing: border-box;
      height: 6px;
      left: 345px;
      position: absolute;
      width: 90px;
    }
  }
}

.slick-desktop {
  .slick-container {
    height: 100%;
    padding: 0 !important;

    .description {
      position: relative;
    }
  }

  img {
    height: 518px;
  }
}

main {
  h3 {
    color: $color-text-header;
    font-size: 3.3rem;
    font-weight: 500;
    line-height: 5rem;
  }
}

.features {
  padding: 90px $padding-left-container;

  h3 {
      margin-bottom: 70px;
  }

  .card-container {
    display: flex;

    .card {
      padding: 30px;
      transition: all ease 0.3s;
      width: 25%;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
        transform: translateY(-10px);
      }

      &:nth-child(2n + 0) {
        border-top: 4px solid $brand-secondary;
        i {
          color: $brand-secondary;
        }
      }

      &:nth-child(2n + 1) {
        border-top: 4px solid $brand-primary;
        i {
          color: $brand-primary;
        }
      }

      h4 {
        font-size: 2.2rem;
        font-weight: 500;
      }

      i {
        font-size: 4.4rem;
        margin-right: 15px;
      }

      p {
        margin: 0;
      }
    }
  }
}

.layouts {
  background: $color-white;
  padding: 70px $padding-left-container 100px $padding-left-container;
  position: relative;

  .preview-container {
      margin: 0 auto;
      width: 70%;
  }

  .phone {
      .screen {
          border: 0;
          width: 99rem;

          img {
              border: 1px solid $border-gadget;
          }

          .description {
              margin-left: 130px;
              margin-top: 60px;

              ul {
                  margin-top: 50px;

                  li {
                      align-items: baseline;
                      display: flex;
                      margin-bottom: 25px;

                      span {
                          font-size: 2rem;
                          line-height: 4rem;
                      }

                      i {
                          color: $brand-secondary;
                          font-size: 1.7rem;
                          margin-right: 20px;
                      }
                  }
              }
          }
      }
  }
}

.start-now {
    background-image: url("/img/beerpong-images/1920px/beer-pong-team-desktop-4.jpg");
    background-attachment: fixed;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 330px;

    .overlay-blue {
      display: flex;
      justify-content: center;
    }

    .teaser {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h3 {
        color: #313131;
        line-height: 5rem;
        text-align: center;
        margin-bottom: 25px;

        span:nth-child(3) {
          display: block;
        }
      }

      .md-button {
        padding: 0 18px !important;
      }
    }
}

footer {
    padding: 30px 0;

    .language-changer {
        margin-bottom: 10px;
        font-family: $font-ubuntu $font-default;;
    }

    li {
        margin-bottom: 15px;
        text-align: center;

        &:last-child {
          margin-bottom: 0;
        }

        a {
            color: $color-fliestext !important;

            &:hover {
                color: $brand-primary !important;
                text-decoration: none;
            }
        }
    }
}
@media (max-width: $screen-lg) {
  .container-laptop {
    display: none;
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-lg) {
  .features {
    .card-container {
      flex-wrap: wrap;
  
      .card {
          width: calc(1/2*100% - (1-1/2)*20px);
          margin: 0 0 40px 0;

          &:nth-child(2n + 1) {
            margin-right: 40px;
          }

          &:nth-child(3) {
            margin-right: 0;
            order: 3;
          }

          &:nth-child(4) {
            margin-right: 40px;
            order: 2;
          }
      }
    }
  }
}

@media (min-width: $screen-md) and (max-width: $screen-lg) {
  header {
    height: 730px;
  }

  .introduction {
    .image-container {
      .phone {
        top: 75px;
      }
    }
  }

  .layouts {
    .preview-container {
      padding: 0 $padding-left-container;
      width: 100%;
    }
    .phone {
      .screen {
        width: 90rem;
      }
    }
  }
}

@media (max-width: $screen-md) {
  .introduction {
    .image-container {
      .phone {
        display: none;
      }
    }
  }

  .features {
    .card-container {
      .card {
          &:hover {
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            transform: none;
        }
      }
    }
  }

  .layouts {
    .phone {
      height: 60rem;
      margin: 0 auto;

      .screen {
        margin: -27rem -14.5rem;
        width: 29rem; //TODO

        .description {
          display: none;
        }
      }

      .speaker {
        top: 12px;
      }

      .home {
        bottom: 7px
      }
    }
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
  .introduction {
    h1 {
      font-size: 4.2rem;
      line-height: 6.8rem;
      width: 390px;
    }

    h2 {
      font-size: 2.2rem;
    }
  }

  main {
    h3 {
      font-size: 3rem;
    }
  }

  .features {
      padding: 50px 45px 20px 45px;

      h3 {
        margin-bottom: 50px;
      }

      .card-container {
        .card {
          i {
            font-size: 3.8rem;
          }
        }
      }
  }

  .layouts {
    padding: 60px 45px 90px 45px;
  }

  .start-now {
    height: 260px;
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-4.jpg");

  }

  footer {
    padding: 20px 0;
  }

}

@media (max-width: $screen-sm) {
  .heading {
    height: 100vh;
  }

  header {
    height: auto;
  }

    .introduction {
      h2 {
        color: rgb(15, 15, 15);
      }
    }
}

@media (min-width: $screen-xs2) and (max-width: $screen-sm) {
  .introduction {
    h1 {
      font-size: 3.7rem;
      line-height: 6rem;
      width: 340px;
    }

    h2 {
      font-size: 2rem;
      line-height: 4.5rem;
      margin-right: 45px;
      width: auto;
    }
  }

  main {
    h3 {
      font-size: 2.8rem;
    }
  }

  .features {
    padding: 30px 45px 15px 45px;

    h3 {
      margin-bottom: 35px;
    }

    .card-container {
      flex-wrap: wrap;

      .card {
        margin: 0 0 30px 0;
        width: 100%;

        i {
          font-size: 3.5rem;
        }
      }
    }
  }

  .layouts {
    padding: 40px 10px 70px 10px;
  }

  .start-now {
    height: 260px;

    .teaser {
      h3 {
        font-size: 2.4rem;
      }
    }
    
  }

  footer {
    padding: 20px 0;
  }
}

@media (min-width: $screen-xs2) and (max-width: $screen-lg) {
  .start-now {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-4.jpg");
  }

  .heading {
    background-image: url("/img/beerpong-images/1200px/beer-pong-team-tablet-4.jpg");
  }
}

@media (max-width: $screen-xs2) {
  nav {
    padding: 10px 0 0 25px;
    // justify-content: center;
    margin-top: 10px;
    width: 100%;
    // display: none;
  }

  .introduction {
    height: 100vh;
    // margin-top: -30px;
    padding: 0 20px;

    h1 {
      font-size: 3.4rem;
      line-height: 5.6rem;
      width: 310px;
    }

    h2 {
      font-size: 1.9rem;
      line-height: 4.2rem;
      margin-right: 45px;
      width: 340px;
    }
  }

  main {
    h3 {
      font-size: 2.6rem;
      line-height: 4.5rem;
    }
  }

  .features {
    padding: 30px 20px 15px 20px;

    h3 {
      margin-bottom: 35px;
    }

    .card-container {
      flex-wrap: wrap;

      .card {
        margin: 0 0 20px 0;
        width: 100%;

        i {
          font-size: 3rem;
        }

        h4 {
          font-size: 2rem;
          margin-top: 25px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .layouts {
    padding: 30px 0 60px 0;

    .preview-container {
      width: 100%;
    }
  }

  .heading {
    background-image: url("/img/beerpong-images/500px/beer-pong-team-mobile-4.jpg");
  }

  .start-now {
    height: 260px;
    background-image: url("/img/beerpong-images/500px/beer-pong-team-mobile-4.jpg");

    .teaser {
      padding: 0 20px;

      h3 {
        font-size: 2.2rem;
        line-height: 4.2rem;

        span:nth-child(2) {
          display: block;
        }
      }
    }
    
  }

  footer {
    padding: 20px 0;
  }
}

@media (max-width: 360px) {
  .headlines {
    padding-top: 30px;
  }

.introduction {
    height: 100vh;
    padding: 0 20px;

    h1 {
      font-size: 3.2rem;
      line-height: 5rem;
      margin: 0 100px 20px 0;
      width: 290px;
    }

    h2 {
      font-size: 1.9rem;
      line-height: 4rem;
      margin-right: 45px;
      margin-bottom: 20px;
      width: 290px;
    }
  }
}
</style>