<template>
  <div>
      <StartseiteDesktop/>
  </div>
</template>

<script>
import store from '@/store/index'
import StartseiteDesktop from '@/components/Startseite/StartseiteDesktop.vue'

export default {
    name: 'startseite',
    components: {
        StartseiteDesktop,
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        }
    }
}
</script>